import request from 'utils/request';

const api = {
  OFFER_DETAIL: '/api/offer/detail',
  OFFER_DETAIL_NEW: '/api/offer/copy/detail',
  UPDATE_OFFER_SOURCE: '/api/offer/updateOfferSource',
  UPDATE_OFFER_DESCRIPTION: '/api/offer/updateOfferDescription',
  UPDATE_OFFER_PAYOUT: '/api/offer/updateOfferPayout',
  UPDATE_OFFER_SETTING: '/api/offer/updateOfferSetting',
  UPDATE_OFFER_ASYNC_INFO: '/api/offer/updateOfferAsyncInfo',
  // aff enabled
  AFF_ENABLED_PAGE: '/api/offer/getApprovals',
  AFF_ENABLED_DELETE: '/api/offer/{offerId}/offerWhite/v2/delete',
  // aff blacklist
  AFF_BLACKLIST: '/api/offer/{offerId}/affBlack/list',
  ADD_AFF_BLACKLIST: '/api/offer/{offerId}/affBlack/add',
  DELETE_AFF_BLACKLIST: '/api/offer/{offerId}/affBlack/delete',
  // aff sub blacklist
  AFF_SUB_BLACKLIST: '/api/offer/{offerId}/affSubBlack/list',
  DELETE_AFF_SUB_BLACKLIST: '/api/offer/{offerId}/affSubBlack/delete',
  BATCH_DELETE_AFF_SUB_BLACKLIST: '/api/offer/{offerId}/affSubBlack/batch/delete',
  // source aff blacklist
  SOURCE_AFF_BLACKLIST: '/api/offer/{offerId}/sourceAffBlack/list',
  // source aff blacklist
  SOURCE_AFF_SUB_BLACKLIST: '/api/offer/{offerId}/sourceAffSubBlack/list',
  // offer aff white list
  OFFER_AFF_SUB_WHITE_LIST: '/api/offer/{offerId}/offerAffSubWhite/list',
  ADD_OFFER_AFF_SUB_WHITE_LIST: '/api/offer/{offerId}/offerAffSubWhite/add',
  DELETE_OFFER_AFF_SUB_WHITE_LIST: '/api/offer/{offerId}/offerAffSubWhite/delete',
  BATCH_DELETE_OFFER_AFF_SUB_WHITE_LIST: '/api/offer/{offerId}/offerAffSubWhite/deleteIds',

  // offer track cap
  AFF_CLICK_CAP_LIST: '/api/offer/{offerId}/affClickCap/list',
  ADD_AFF_CLICK_CAP: '/api/offer/{offerId}/affClickCap/add',
  DELETE_AFF_CLICK_CAP: '/api/offer/{offerId}/affClickCap/delete',

  // offer aff cap
  AFF_CAP_LIST: '/api/offer/{offerId}/affCap/list',

  // offer aff permit
  OFFER_PERMIT_LIST: '/api/offer/{offerId}/permit/aff/list',
  ADD_OFFER_PERMIT: '/api/offer/{offerId}/permit/aff/add',
  DELETE_OFFER_PERMIT: '/api/offer/{offerId}/permit/aff/delete',

  // offer status log
  STATUS_LOG: '/api/offer/{offerId}/status/log',

  // offer timed task
  UPDATE_TIMED_TASK: '/api/offer/addOfferSetting',

  // offer  campaign name
  CHANGE_CAMPAOGN_NAME: '/api/offer/addOrUpdate/campaignNameSetting',

  // offer update impression
  UPDATE_IMPRESSION: '/api/offer/updateOfferImpressionSource',

  // offer update ManagerRule
  UPDATE_MANAGER_RULE: '/api/offer/updateOfferManagerRule',

  // sub id
  SUB_ID_PAGE: '/api/offer/selectSubAffFromRule',
  SUB_ID_ADD: '/api/offer/saveSubAffFromRule',
  SUB_ID_EDIT: '/api/offer/updateSubAffFromRule',
  SUB_ID_DELETE: '/api/offer/deleteSubAffFromRule',

  // update size
  UPDATE_SIZE: '/api/creatives/updateSize',

  // auto mix 其他
  AUTO_MIX_OTHER_ADD: '/api/BidAutoMix/add',
  AUTO_MIX_OTHER_UPDATA: '/api/BidAutoMix/update',
  AUTO_MIX_OTHER_DELETE: '/api/BidAutoMix/delete/',
  AUTO_MIX_OTHER_DETAILS: '/api/BidAutoMix/selectByOffer/',
  // auto mix 基础
  AUTO_MIX_BASE_ADD: '/api/BidAutoMix/saveStrategy',
  AUTO_MIX_BASE_UPDATA: '/api/BidAutoMix/updateStrategy',
  AUTO_MIX_BASE_DELETE: '/api/BidAutoMix/removeStrategy/',
  AUTO_MIX_BASE_DETAILS: '/api/BidAutoMix/findStrategyByOfferId/',
  // auto mix 投放数据包
  AUTO_MIX_DATA_PACKET: '/api/admin/v3/task/info/add/',
  AUTO_MIX_DATA_PACKET_DETAILS: '/api/admin/v3/task/data/source/get',

  AUTO_MIX_ALL_ADD: '/api/BidAutoMix/saveAll',

  AUTO_MIX_COUNT: '/api/BidAutoMix/forecastDataSourceCount',
  AUTO_BATCH_MIX: 'api/BidAutoMix/batchMix',
  FORECAST_VBA_CVR: '/api/BidAutoMix/forecastVbaCvr',

  COPY_AUTO__MIX: 'api/BidAutoMix/batchMIxCopy',

  //pid Num
  PID_NUM_UPDATE: '/api/offerPidNum/updateByOfferId',
  PID_NUM_DETAIL: '/api/offerPidNum/detail/',
  DATA_ALG_STRATEGY_LIST: '/api/admin/v3/task/data/alg/allStrategy',

  UPDATE_OFFER_IS_DIRECT_LINK: '/api/offer/updateOfferIsDirectLink',
  //修改子渠道状态
  UPDATE_SUBID_STATUS: '/api/offer/updateSubIdStatus',

  BATCH_UPDATE_SUBID_STATUS: '/api/offer/batchUpdateSubIdStatus',

  UPDATE_OFFER_ADVANCED_PROXY: '/api/offer/updateOfferAdvancedProxy',
};

export function getOfferDetail(offerId) {
  return request({
    url: api.OFFER_DETAIL,
    method: 'get',
    params: {
      offerId,
    },
  });
}

export function getOfferDetailNew(offerId) {
  return request({
    url: api.OFFER_DETAIL_NEW,
    method: 'get',
    params: {
      offerId,
    },
  });
}

export function updateOfferSource(data) {
  return request({
    url: api.UPDATE_OFFER_SOURCE,
    method: 'put',
    data,
  });
}

export function updateOfferDescription(data) {
  return request({
    url: api.UPDATE_OFFER_DESCRIPTION,
    method: 'put',
    data,
  });
}

export function updateOfferPayout(data) {
  return request({
    url: api.UPDATE_OFFER_PAYOUT,
    method: 'put',
    data,
  });
}

export function updateOfferSetting(data) {
  return request({
    url: api.UPDATE_OFFER_SETTING,
    method: 'put',
    data,
  });
}

export function updateOfferAsyncInfo(data) {
  return request({
    url: api.UPDATE_OFFER_ASYNC_INFO,
    method: 'put',
    data,
  });
}

// aff blacklist
export function getAffBlacklist(offerId, query) {
  const url = api.AFF_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function deleteAffBlacklist(offerId, affiliateIds) {
  const url = api.DELETE_AFF_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      affiliateIds,
    },
  });
}

export function getAffSubBlacklist(offerId, query) {
  const url = api.AFF_SUB_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function deleteAffSubBlacklist(offerId, affiliateIds) {
  const url = api.DELETE_AFF_SUB_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      affiliateIds,
    },
  });
}

export function batchDelAffSubBlacklist(offerId, ids) {
  const url = api.BATCH_DELETE_AFF_SUB_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// aff blacklist & aff sub blacklist
export function addAffBlacklist(offerId, data) {
  const url = api.ADD_AFF_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

// Source Aff blacklist
export function getSourceAffBlacklist(offerId) {
  const url = api.SOURCE_AFF_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
  });
}

// Source Aff Sub blacklist
export function getSourceAffSubBlacklist(offerId, query) {
  const url = api.SOURCE_AFF_SUB_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

// offer Aff Sub white list
export function getOfferAffSubWhiteList(offerId, query) {
  const url = api.OFFER_AFF_SUB_WHITE_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function addOfferAffSubWhiteList(offerId, data) {
  const url = api.ADD_OFFER_AFF_SUB_WHITE_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function deleteOfferAffSubWhiteList(offerId, affiliateIds) {
  const url = api.DELETE_OFFER_AFF_SUB_WHITE_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: { affiliateIds },
  });
}

export function batchDelOfferAffSubWhiteList(offerId, ids) {
  const url = api.BATCH_DELETE_OFFER_AFF_SUB_WHITE_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// offer track cap
export function getAffClickCap(offerId, query) {
  const url = api.AFF_CLICK_CAP_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function addAffClickCap(offerId, data) {
  const url = api.ADD_AFF_CLICK_CAP.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function deleteAffClickCap(offerId, ids) {
  const url = api.DELETE_AFF_CLICK_CAP.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    data: {
      ids,
    },
  });
}

// offer aff cap
export function getAffCapList(offerId) {
  const url = api.AFF_CAP_LIST.replace('{offerId}', offerId);
  console.log(url);
  return request({
    url,
    method: 'get',
  });
}

// offer aff permit
export function getOfferPermitList(offerId, affiliateIds) {
  const url = api.OFFER_PERMIT_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    data: {
      affiliateIds,
    },
  });
}

export function addOfferPermit(offerId, data) {
  const url = api.ADD_OFFER_PERMIT.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function delOfferPermit(offerId, affiliateIds) {
  const url = api.DELETE_OFFER_PERMIT.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      affiliateIds,
    },
  });
}

// offer status log
export function getStatusLog(offerId, query) {
  const url = api.STATUS_LOG.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function updateOfferTimedTask(data) {
  return request({
    url: api.UPDATE_TIMED_TASK,
    method: 'post',
    data,
  });
}

export function changeOfferCampaignName(data) {
  return request({
    url: api.CHANGE_CAMPAOGN_NAME,
    method: 'post',
    data,
  });
}

export function updateImpression(data) {
  return request({
    url: api.UPDATE_IMPRESSION,
    method: 'put',
    data,
  });
}

export function updateOfferManagerRule(data) {
  return request({
    url: api.UPDATE_MANAGER_RULE,
    method: 'put',
    data,
  });
}

// sub id
export function fetchSubIdPage(query) {
  const url = api.SUB_ID_PAGE;
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function handleSubIdAdd(data) {
  return request({
    url: api.SUB_ID_ADD,
    method: 'post',
    data,
  });
}

export function handleSubIdEdit(data) {
  return request({
    url: api.SUB_ID_EDIT,
    method: 'post',
    data,
  });
}

export function handleSubIdStatus(data) {
  return request({
    url: api.UPDATE_SUBID_STATUS,
    method: 'post',
    data,
  });
}

export function handleBatchSubIdStatus(data) {
  return request({
    url: api.BATCH_UPDATE_SUBID_STATUS,
    method: 'post',
    data,
  });
}

export function handleSubIdDel(data) {
  return request({
    url: api.SUB_ID_DELETE + data,
    method: 'delete',
    // data,
  });
}

export function handleUpdateSize(data) {
  return request({
    url: api.UPDATE_SIZE,
    method: 'post',
    data,
  });
}

// 获取auto mix 其他渠道比例详情
export function fetchAutoMixOther(params) {
  const url = api.AUTO_MIX_OTHER_DETAILS + params;
  return request({
    url,
    method: 'get',
  });
}

// 添加auto mix 其他渠道比例
export function handleAddAutoMixOther(data) {
  return request({
    url: api.AUTO_MIX_OTHER_ADD,
    method: 'post',
    data,
  });
}

// 更新auto mix 其他渠道比例
export function handleUpdataAutoMixOther(data) {
  return request({
    url: api.AUTO_MIX_OTHER_UPDATA,
    method: 'post',
    data,
  });
}

// 删除auto mix 其他渠道比例
export function handleRemoveAutoMixOther(data) {
  return request({
    url: api.AUTO_MIX_OTHER_DELETE + data,
    method: 'post',
  });
}

// 更新auto mix 基础配置
export function handleAddAutoMixBase(data) {
  return request({
    url: api.AUTO_MIX_BASE_ADD,
    method: 'post',
    data,
  });
}

export function handleUpdateAutoMixBase(data) {
  return request({
    url: api.AUTO_MIX_BASE_UPDATA,
    method: 'post',
    data,
  });
}

// 获取auto mix 其他渠道比例详情
export function fetchAutoMixBase(params) {
  const url = api.AUTO_MIX_BASE_DETAILS + params;
  return request({
    url,
    method: 'get',
  });
}

export function handleRemoveAutoMixBase(data) {
  return request({
    url: api.AUTO_MIX_BASE_DELETE + data,
    method: 'post',
  });
}

export function handleAutoMixDataPacketAdd(data) {
  return request({
    url: api.AUTO_MIX_DATA_PACKET,
    method: 'post',
    data,
  });
}

export function fetchAutoMixDataPacket(params) {
  const url = api.AUTO_MIX_DATA_PACKET_DETAILS;
  return request({
    url,
    method: 'get',
    params,
  });
}

// aff enabled
export function fetchEnabledAffiliatesPage(query) {
  const url = api.AFF_ENABLED_PAGE;
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function handleEnabledAffDelete(offerId, ids) {
  const url = api.AFF_ENABLED_DELETE.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// 添加auto mix 统一保存
export function handleAutoMixAddAll(data) {
  return request({
    url: api.AUTO_MIX_ALL_ADD,
    method: 'post',
    data,
  });
}

// 预估数据包数量
export function handleForecastCount(data) {
  return request({
    url: api.AUTO_MIX_COUNT,
    method: 'post',
    data,
  });
}

// 预估VBA 转化率
export function forecastVbaCvr(data) {
  return request({
    url: api.FORECAST_VBA_CVR,
    method: 'post',
    data,
  });
}

// 批量配置混量
export function handleBatchMix(data) {
  return request({
    url: api.AUTO_BATCH_MIX,
    method: 'post',
    data,
  });
}

export function handleCopyMix(data) {
  return request({
    url: api.COPY_AUTO__MIX,
    method: 'post',
    data,
  });
}

// 获得PID数量详情
export function pidNumDetail(offerId) {
  return request({
    url: api.PID_NUM_DETAIL + offerId,
    method: 'get',
  });
}

// pid数量更新
export function pidNumUpdate(data) {
  return request({
    url: api.PID_NUM_UPDATE,
    method: 'post',
    data,
  });
}

export function getAlgStrategeList(query) {
  return request({
    url: api.DATA_ALG_STRATEGY_LIST,
    method: 'get',
    params: query,
  });
}

export function updateOfferIsDirectLink(data) {
  return request({
    url: api.UPDATE_OFFER_IS_DIRECT_LINK,
    method: 'post',
    data,
  });
}

export function updateOfferAdvancedProxy(data) {
  return request({
    url: api.UPDATE_OFFER_ADVANCED_PROXY,
    method: 'post',
    data,
  });
}
