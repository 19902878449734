import request from 'utils/request';
const api = {
  WHITE_PAGE: '/api/reject/whitelist/list',
  WHITE_DELETES: '/api/reject/whitelist/del',
  WHITE_ADD: '/api/reject/whitelist/add',
  WHITE_SUPPLEMENT_PAGE: '/api/whitelist/list',
  WHITE_SUPPLEMENT_DELETES: '/api/whitelist/remove',
  WHITE_SUPPLEMENT_ADD: '/api/whitelist/save',
  // adjustPB
  ADJUST_PB_PAGE: '/api/adjust/domain/list',
  ADJUST_PB_ADD: '/api/adjust/domain/save',
  ADJUST_PB_NEW_DOMAIN: '/api/adjust/domain/listAllPendingAndActive',
  ADJUST_PB_OFF: '/api/adjust/domain/off',
  // adjustPBBlackList
  BLACK_ADJUST_PAGE: '/api/adjust/blackList/list',
  BLACK_ADJUST_DELETES: '/api/adjust/blackList/del',
  BLACK_ADJUST_ADD: '/api/adjust/blackList/save',
  PID_CLICK_CAP_PAGE: '/api/pid/click/list',
  PID_CLICK_CAP_DELETE: '/api/pid/click/delete',
  PID_CLICK_CAP_ADD: '/api/pid/click/add',
  // offerPidNum
  OFFER_PID_NUM_PAGE: '/api/offerPidNum/list',
  OFFER_PID_NUM_DELETE: '/api/offerPidNum/del',
  OFFER_PID_NUM_ADD: '/api/offerPidNum/add',

  // 网盟 混量渠道切换
  REPLACE_AUTOMIX_PAGE: '/api/replace/autoMix/list',
  REPLACE_AUTOMIX_ADD: '/api/replace/autoMix/add',
  REPLACE_AUTOMIX_DELETE: '/api/replace/autoMix/remove',
  REPLACE_AUTOMIX_EDIT: '/api/replace/autoMix/edit',

  REPLACE_AUTOMIX_SELECT_ONE: '/api/replace/autoMix/selectRelaceRule',
  REPLACE_AUTOMIX_REMOVE: '/api/replace/autoMix/removeMixAff',

  //algorithmStrategy
  ALG_STRATEGY_PAGE: '/api/admin/v3/task/data/alg/strategyPager',
  ALG_STRATEGY_ADD: '/api/admin/v3/task/alg/strategy/save',
  ALG_STRATEGY_DEL: '/api/admin/v3/task/alg/strategy/delete',
  ALG_STRATEGY_UPDATES: '/api/admin/v3/task/alg/strategy/updateStrategies',
  DATA_ALG_STRATEGY_LIST: '/api/admin/v3/task/data/alg/allStrategy',

  // 子渠道关闭规则
  SUB_CLOSE_SETTING_GET: '/api/subCloseSetting/getOne',
  SUB_CLOSE_SETTING_UPDATE: '/api/subCloseSetting/update',
  SUB_CLOSE_SETTING_OFFER_SUB_REDIS: '/api/subCloseSetting/offerSubRedis',

  // source prod weight 维度
  SOURCE_PROD_WRIGHT_ADD: '/api/sourceProdWright/add',
  SOURCE_PROD_WRIGHT_UPDATE: '/api/sourceProdWright/update',
  SOURCE_PROD_WRIGHT_DELETE: '/api/sourceProdWright/delete',
  SOURCE_PROD_WRIGHT_LIST: '/api/sourceProdWright/list',

  // country prod 维度 cvr
  COUNTRY_PROD_CVR_ADD: '/api/countryProdWright/add',
  COUNTRY_PROD_CVR_UPDATE: '/api/countryProdWright/update',
  COUNTRY_PROD_CVR_DELETE: '/api/countryProdWright/delete',
  COUNTRY_PROD_CVR_LIST: '/api/countryProdWright/list',
};

export function subCloseGetOne() {
  return request({
    url: api.SUB_CLOSE_SETTING_GET,
    method: 'get',
  });
}

export function subCloseOfferSubRedis() {
  return request({
    url: api.SUB_CLOSE_SETTING_OFFER_SUB_REDIS,
    method: 'get',
  });
}

export function subCloseUpdate(data) {
  return request({
    url: api.SUB_CLOSE_SETTING_UPDATE,
    method: 'post',
    data,
  });
}

export function fetchWhitePage(params) {
  return request({
    url: api.WHITE_PAGE,
    method: 'get',
    params,
  });
}

export function handleWhiteDelete(id) {
  return request({
    url: api.WHITE_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function handleWhiteAdd(data) {
  return request({
    url: api.WHITE_ADD,
    method: 'post',
    data,
  });
}

export function fetchSupplementPage(params) {
  return request({
    url: api.WHITE_SUPPLEMENT_PAGE,
    method: 'get',
    params,
  });
}

export function handleSupplementDelete(prod) {
  return request({
    url: api.WHITE_SUPPLEMENT_DELETES,
    method: 'post',
    params: {
      prod,
    },
  });
}

export function handleSupplementAdd(prod) {
  return request({
    url: api.WHITE_SUPPLEMENT_ADD,
    method: 'post',
    params: prod,
  });
}

/**
 * 获取 adjustPB列表（分页）
 * @param params
 * @returns {*}
 */
export function fetchAdjustPBPage(params) {
  return request({
    url: api.ADJUST_PB_PAGE,
    method: 'get',
    params,
  });
}

/**
 * 添加AdjustPB
 * @param data
 * @returns {AxiosPromise}
 */
export function handleAdjustPBAdd(data) {
  return request({
    url: api.ADJUST_PB_ADD,
    method: 'post',
    data,
  });
}

/**
 * 获取没选择的pb domain
 * @param params
 * @returns {AxiosPromise}
 */
export function fetchAdjustPBNewDomain(params) {
  return request({
    url: api.ADJUST_PB_NEW_DOMAIN,
    method: 'get',
    params,
  });
}

/**
 * OFF按钮
 * @param data
 * @returns {AxiosPromise}
 */
export function handleAdjustPBOff(params) {
  return request({
    url: api.ADJUST_PB_OFF,
    method: 'get',
    params,
  });
}

export function fetchBlackAdjustPage(params) {
  return request({
    url: api.BLACK_ADJUST_PAGE,
    method: 'get',
    params,
  });
}

export function handleBlackAdjustDelete(id) {
  return request({
    url: api.BLACK_ADJUST_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function handleBlackAdjustAdd(data) {
  return request({
    url: api.BLACK_ADJUST_ADD,
    method: 'post',
    data,
  });
}

export function fetchPidClickCapPage(params) {
  return request({
    url: api.PID_CLICK_CAP_PAGE,
    method: 'get',
    params,
  });
}

export function handlePidClickCapDelete(id) {
  return request({
    url: api.PID_CLICK_CAP_DELETE,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function handlePidClickCapAdd(data) {
  return request({
    url: api.PID_CLICK_CAP_ADD,
    method: 'post',
    data,
  });
}

export function fetchOfferPidNumPage(params) {
  return request({
    url: api.OFFER_PID_NUM_PAGE,
    method: 'get',
    params,
  });
}

export function handleOfferPidNumDelete(id) {
  return request({
    url: api.OFFER_PID_NUM_DELETE,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function handleOfferPidNumAdd(data) {
  return request({
    url: api.OFFER_PID_NUM_ADD,
    method: 'post',
    data,
  });
}

export function fetchReplaceAutoMixRulePage(params) {
  return request({
    url: api.REPLACE_AUTOMIX_PAGE,
    method: 'get',
    params,
  });
}

export function replaceAutoMixRuleAdd(data) {
  return request({
    url: api.REPLACE_AUTOMIX_ADD,
    method: 'post',
    data,
  });
}

export function replaceAutoMixRuleEdit(data) {
  return request({
    url: api.REPLACE_AUTOMIX_EDIT,
    method: 'post',
    data,
  });
}

export function replaceAutoMixRuleDelete(id) {
  return request({
    url: api.REPLACE_AUTOMIX_DELETE,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function getReplaceAutoMixAff(offerId) {
  return request({
    url: api.REPLACE_AUTOMIX_SELECT_ONE,
    method: 'get',
    params: {
      offerId,
    },
  });
}

export function replaceAutoMixAffDelete(offerId) {
  return request({
    url: api.REPLACE_AUTOMIX_REMOVE,
    method: 'delete',
    params: {
      offerId,
    },
  });
}

/**
 * 获取 algorithom 策略列表（分页）
 * @param params
 * @returns {*}
 */
export function fetchAlgStrategyPage(params) {
  return request({
    url: api.ALG_STRATEGY_PAGE,
    method: 'get',
    params,
  });
}

/**
 * 添加algorithom策略
 * @param data
 * @returns {AxiosPromise}
 */
export function handleAlgStrategyAdd(data) {
  return request({
    url: api.ALG_STRATEGY_ADD,
    method: 'post',
    data,
  });
}

/**
 * delete algorithom策略
 * @param params
 * @returns {AxiosPromise}
 */
export function deleteAlgStrategy(id) {
  return request({
    url: api.ALG_STRATEGY_DEL,
    method: 'delete',
    params: {
      id,
    },
  });
}

/**
 * updateStrategies 批量更新algorithom策略
 * @param params
 * @returns {AxiosPromise}
 */
export function updateStrategies(data) {
  return request({
    url: api.ALG_STRATEGY_UPDATES,
    method: 'post',
    data,
  });
}

/**
 * getAlgStrategyList 全部algorithom策略
 * @param query
 * @returns {*}
 */
export function getAlgStrategyList(query) {
  return request({
    url: api.DATA_ALG_STRATEGY_LIST,
    method: 'get',
    params: query,
  });
}

// source prod 维度的权重

export function addSourceProdWeight(data) {
  return request({
    url: api.SOURCE_PROD_WRIGHT_ADD,
    method: 'post',
    data,
  });
}

export function updateSourceProdWeight(data) {
  return request({
    url: api.SOURCE_PROD_WRIGHT_UPDATE,
    method: 'post',
    data,
  });
}

export function deleteSourceProdWeight(id) {
  return request({
    url: api.SOURCE_PROD_WRIGHT_DELETE,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function fetchSourceProdWeight(params) {
  return request({
    url: api.SOURCE_PROD_WRIGHT_LIST,
    method: 'get',
    params,
  });
}

// country prod 维度的cvr
export function fetchCountryProdCvr(params) {
  return request({
    url: api.COUNTRY_PROD_CVR_LIST,
    method: 'get',
    params,
  });
}

export function deleteCountryProdCvr(id) {
  return request({
    url: api.COUNTRY_PROD_CVR_DELETE,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function updateCountryProdCvr(data) {
  return request({
    url: api.COUNTRY_PROD_CVR_UPDATE,
    method: 'post',
    data,
  });
}

export function addCountryProdCvr(data) {
  return request({
    url: api.COUNTRY_PROD_CVR_ADD,
    method: 'post',
    data,
  });
}
